<template>
  <div id="page-connect" class="page page-connect">
    <div ref="container" class="container"></div>
  </div>
</template>

<script>
import config from '@/config';
import iframe from '@/mixins/iframe';

export default {
  props: {
    fullPath: String,
  },

  data() {
    return {
      accessToken: config.getAccessToken(),
      loading: false,

      iframe: null,
    };
  },

  computed: {
    iframeSrc() {
      const baseURL = config.get('siteV2URL');
      let url = new URL(decodeURIComponent(this.fullPath), baseURL);

      if (this.accessToken) {
        url = new URL(`/auth/login?token=${this.accessToken}&redirect=${url.pathname + url.search}`, baseURL);
      }

      return url.href;
    },
  },

  mixins: [iframe],

  mounted() {
    this.$store.commit('loading/SET_TRUE');
    this.iframeElement = this.createIframe(this.iframeSrc);
    this.$refs.container.appendChild(this.iframeElement);

    this.checkIframeLoaded(this.iframeElement)
      .catch((_) => {
        if (this.$route.name === 'PageConnect') {
          this.$router.back();
          window.open(this.iframeSrc, '_self');
        }
      })
      .finally(() => {
        this.$store.commit('loading/SET_FALSE');
      });
  },
};
</script>

<style lang="scss" scoped>
.page {
  &.page-connect {
    @include fill-viewport-min-height;

    > .container {
      @include fsa;
    }
  }
}
</style>
